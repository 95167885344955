import { createApp } from 'vue'
import vuesax from 'vuesax-alpha'
import 'vuesax-alpha/dist/vuesax.css'
import App from './App.vue'
import 'vue-particle-line/dist/vue-particle-line.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import animated from 'animate.css'
import router from "@/router";
import '../src/assets/styles/main.css'
import Antd from 'ant-design-vue';
import naive from 'naive-ui'
import 'vue-waterfall-plugin-next/dist/style.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';

const app = createApp(App)
app.use(vuesax)
app.use(ElementPlus)
app.use(animated)
app.use(router)
app.use(Antd)
app.use(naive)
app.use(Viewer)
app.use(vue3videoPlay)
app.use(VuePlyr)
app.use(vue3PhotoPreview);

app.mount('#app')


