<script setup>

import {ref} from "vue";

const workList = ref([
  {
    number: '01',
    name: '遗憾遇见你',
    actor: '饰演：孟瑶',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/遗憾遇见你.jpg',
    src: 'https://www.mgtv.com/h/649433.html'
  },
  {
    number: '02',
    name: '回到河岸',
    actor: '饰演：吴易',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/回到河岸.jpg',
    src: 'https://www.bilibili.com/video/BV19g411j76S/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a'
  },
  {
    number: '03',
    name: '目光里的一切',
    actor: '饰演：小丑',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/目光里的一切.jpg',
    src: 'https://www.bilibili.com/video/BV1Hy4y1A73q/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a'
  },
  {
    number: '04',
    name: '离校日',
    actor: '饰演：叶豫文',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/离校日.jpg',
    src: 'https://www.bilibili.com/video/BV11u4y1n7H7/?spm_id_from=333.337.search-card.all.click'
  },
  {
    number: '05',
    name: '谢公子的酒',
    actor: '饰演：小棠',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/谢公子的酒.webp',
    src: 'https://v.qq.com/x/cover/mzc00200dm7ebei/f004247y997.html'
  },
  {
    number: '06',
    name: '淙淙',
    actor: '饰演：淙淙',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/淙淙.jpg',
    src: 'https://www.bilibili.com/video/BV1Ag4y1o7ZV/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a'
  },
  {
    number: '07',
    name: '二次呼吸',
    actor: '饰演：南溪',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/二次呼吸.jpg',
    src: 'https://www.bilibili.com/video/BV1vZ421g73v/?spm_id_from=333.337.search-card.all.click'
  },
  {
    number: '08',
    name: '完蛋！我被美女包围了！',
    actor: '饰演：郑梓妍',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/完蛋！我被美女包围了！.jpg',
    src: 'https://store.steampowered.com/app/2322560/_/?l=schinese'
  },
  {
    number: '09',
    name: '热搜',
    actor: '饰演：温妮',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/热搜.jpg',
    src: 'https://www.iqiyi.com/v_upobmztlag.html?vfrm=pcw_album_auto&rfr=https://cn.bing.com/'
  },
  {
    number: '10',
    name: '蓟州疑云',
    actor: '饰演：小铺兵',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/蓟州疑云.jpg',
    src: 'https://www.douban.com/location/drama/36045601/#:~:text=%E8%93%9F%E5%B7%9E%E7%96%91%E4%BA%91%E7%9A%84%E5%89%A7%E6%83%85%E7%AE%80%E4%BB%8B'
  },
  {
    number: '11',
    name: '咸鱼反击',
    actor: '饰演：郭苗',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/咸鱼反击.jpg',
    src: 'https://www.sohu.com/picture/768422261#:~:text=%E5%89%A7%E5%90%8D%EF%BC%9A%E3%80%8A%E5%92%B8%E9%B1%BC%E5%8F%8D%E5%87%BB%E3%80%8B'
  },
  {
    number: '12',
    name: '暗夜浪漫',
    actor: '饰演：许希/许望',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/暗夜浪漫.jpg',
    src: 'https://www.mgtv.com/h/680479.html'
  },
  {
    number: '13',
    name: '命运游戏2',
    actor: '饰演：方芷莲',
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/%E9%92%9F%E6%99%A8%E7%91%B6%E5%91%BD%E8%BF%902.jpg',
    src: 'https://store.steampowered.com/app/3218600/2/?l=schinese'
  },
])

</script>

<template>
  <div class="whole">
    <div class="background-image-container">
      <div class="personal-info animated fadeIn">
        <img alt="" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶个人简介.webp" style="height: 250px; border-radius: 0.5rem">
        <div class="personal-info-text">
          <p style="color: white; text-align: left; margin-left: 1rem; font-size: 25px; font-weight: 700; margin-top: 0.5rem">
            钟晨瑶</p>
          <p style="color: #ebebeb; text-align: left; margin-left: 1rem; font-size: 16px; font-weight: 400">Zhong
            Chenyao</p>
          <div style="display: flex; align-items: center; margin-left: 0.9rem; margin-top: 0.8rem">
            <img style="height: 10px; padding: 0" src="../assets/icon/Subtraction.webp" alt="">
            <p style="color: #ebebeb; text-align: left; font-size: 12px; margin-left: 0.3rem; padding-top: 0.1rem; font-weight: 500">
              中国内地女演员</p>
          </div>
          <div style="width: 95%">
            <a-divider style="margin-left: 1rem; background-color: #ebebeb;"/>
          </div>
          <p style="text-align: left; color: rgba(235,235,235,0.5); font-size: 14px; padding: 1rem; text-indent: 1.8rem">
            钟晨瑶，1998年出生于福建省三明市，中国内地女演员，毕业于中央戏剧学院。2021年，主演古装爱情轻喜剧《谢公子的酒》，从而进入演艺圈。2023年，主演的剧情电影《热搜》和《二次呼吸》上映。同年，主演的真人恋爱影视游戏《完蛋！我被美女包围了！》上线，凭借郑梓妍一角为人所熟知。2024年，出演原创话剧《蓟州疑云》。待播作品：网剧《咸鱼反击》。
          </p>
        </div>
      </div>
      <div class="animated fadeIn" style="color: white; font-size: 20px; font-weight: bolder; margin-top: 5rem">
        主要作品
      </div>
      <div class="animated fadeIn" style="color: white; font-size: 13px; font-weight: lighter">ZCY WORKS</div>
      <div style="width: 55%; margin: 4rem auto;">
        <div v-for="item in workList" :key="item.number">
          <a :href="item.src" target="_blank">
            <div class="work-item animated fadeIn" style="display: flex; padding: 2rem">
              <div>
                <div class="work-font-index">{{ item.number }}</div>
                <div class="work-font-name">{{ item.name }}</div>
                <div class="work-font-actor">{{ item.actor }}</div>
              </div>
              <img alt="" :src="item.img" class="work-img">
            </div>
            <div class="work-divider-line"/>
          </a>
        </div>
      </div>
      <div style="color: rgba(255,255,255,0.5); margin-bottom: 2rem">期待瑶瑶姐未来越来越多优秀的作品～🥳🥳🥳</div>
      <n-back-top :right="60" :bottom="60"/>
    </div>
  </div>
</template>

<style scoped>
.whole {
  min-height: 120vh;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: url("https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/pricing-bg-dot-shape.png");
  background-attachment: fixed;
}

.background-image-container {
  width: 1500px;
  background-size: cover;
  background-position: center;
}

.personal-info {
  display: flex;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(235, 235, 235, 0.15);
}

.personal-info-text {
  width: 100%;
}

.animated.fadeIn {
  animation-duration: 1.5s; /* 设置动画持续时间为 2 秒 */
}

.work-font-index {
  color: #ebebeb;
  font-size: 80px;
  text-align: left;
}

.work-font-name {
  color: #ebebeb;
  text-align: left;
  font-size: 30px;
}

.work-font-actor {
  color: rgba(235, 235, 235, 0.6);
  text-align: left;
  margin-top: 0.5rem;
}

.work-img {
  width: 400px;
  margin-left: auto;
  border-radius: 0.3rem;
  transition: width 0.3s ease;
}

.work-divider-line {
  background-color: #ebebeb;
  height: 1px;
}

.work-item {
  padding: 0.5rem;
}

.work-item:hover + .work-divider-line {
  background-color: #26649d;
}

.work-item:hover .work-font-index {
  color: #26649d !important;
}

.work-item:hover .work-font-name {
  color: #26649d !important;
}

.work-item:hover .work-img {
  width: 450px;
}

.animated.fadeIn {
  animation-duration: 3s; /* 设置动画持续时间为 2 秒 */
}

</style>