import MusicPage from "@/page/MusicPage.vue";
import {createRouter, createWebHashHistory} from 'vue-router'
import FilmPage from "@/page/FilmPage.vue";
import ImagePage from "@/page/ImagePage.vue";
import MessagePage from "@/page/MessagePage.vue";
import FilmPageH5 from "@/page/FilmPageH5.vue";
import MusicPageH5 from "@/page/MusicPageH5.vue";
import IndexPageH5 from "@/page/IndexPageH5.vue";
import IndexPage from "@/page/IndexPage.vue";
import ImagePageH5 from "@/page/ImagePageH5.vue";
import MessagePageH5 from "@/page/MessagePageH5.vue";
import PostPageH5 from "@/page/PostPageH5.vue";

const width = window.innerWidth;
const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: 'is-active',
    routes: [
        {
            path: '/',
            name: 'index',
            component: width <= 768 ? IndexPageH5 : IndexPage
        },
        {
            path: '/music',
            name: 'music',
            component: width <= 768 ? MusicPageH5 : MusicPage
        },
        {
            path: '/film',
            name: 'film',
            component: width <= 768 ? FilmPageH5 : FilmPage
        },
        {
            path: '/image',
            name: 'image',
            component: width <= 768 ? ImagePageH5 : ImagePage
        },
        {
            path: '/message',
            name: 'message',
            component: width <= 768 ? MessagePageH5 : MessagePage
        },
        {
            path: '/post',
            name: 'post',
            component: width <= 768 ? PostPageH5 : IndexPage
        }
    ]
})

router.afterEach(() => {
    window.scrollTo(0, 0);
});

export default router
