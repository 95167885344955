<template>
  <MyNavbarH5 class="nav" v-if="isMobile"></MyNavbarH5>
  <MyNavbar class="nav" v-if="!isMobile"></MyNavbar>
  <router-view/>
  <MyFooterH5 class="footer" v-if="isMobile"></MyFooterH5>
  <MyFooter class="footer" v-if="!isMobile"></MyFooter>
</template>

<script setup>
import MyNavbar from "@/components/MyNavbar.vue";
import MyFooter from "@/components/MyFooter.vue";
import $ from 'jquery';
import MyNavbarH5 from "@/components/MyNavbarH5.vue";

$(window).on("load", function () {
  $(".loader-wrapper").fadeOut("fast")
});

import {onBeforeMount, ref} from 'vue';
import MyFooterH5 from "@/components/MyFooterH5.vue";

const isMobile = ref(false);

function checkResolution() {
  const width = window.innerWidth;
  isMobile.value = width < 768;
}

window.addEventListener('load', checkResolution);
window.addEventListener('resize', checkResolution);

onBeforeMount(() => {
  checkResolution()
})

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgba(0, 0, 0, 1);
}
html {
  background-color: rgba(0, 0, 0, 1);
}
</style>
