<script setup>
import {onMounted, ref} from 'vue';
import $ from 'jquery';

const isVisible = ref(false);

onMounted(() => {
  // 组件加载后，设置为可见
  setTimeout(() => {
    isVisible.value = true;
  }, 5000)
});


// 判断元素是否在屏幕中央
function isElementInViewport(el, tolerance) {
  if (!el) return false; // 确保 el 存在
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // 计算视口竖直中央的位置
  const viewportCenterY = windowHeight / 2;


  // 判断元素的顶部是否在视口竖直中央范围内
  console.log(rect.top, rect.bottom);
  return rect.top >= (viewportCenterY - tolerance) && rect.top <= (viewportCenterY + tolerance);
}

$(window).on('scroll load', function () {
  const $myFadeInLeft = $('.my-fadeInLeft');
  const $myFadeInRight = $('.my-fadeInRight');
  const $myFadeIn = $('.my-fadeIn');
  const $timeline_broadcast_item = $('.timeline-broadcast-item');

  if ($myFadeInLeft.length) {
    $myFadeInLeft.each(function () {
      const $this = $(this); // 当前元素
      // 检查当前元素是否在视口中
      if (isElementInViewport(this, 660)) {
        $this.addClass('hidden')
        $this.removeClass('hidden').addClass('animated fadeInLeft');
      }
    });
  }
  if ($myFadeInRight.length) {
    $myFadeInRight.each(function () {
      const $this = $(this); // 当前元素
      // 检查当前元素是否在视口中
      if (isElementInViewport(this, 660)) {
        $this.addClass('hidden')
        $this.removeClass('hidden').addClass('animated fadeInRight');
      }
    });
  }
  if ($myFadeIn.length) {
    $myFadeIn.each(function () {
      const $this = $(this); // 当前元素
      // 检查当前元素是否在视口中
      if (isElementInViewport(this, 660)) {
        $this.addClass('hidden')
        $this.removeClass('hidden').addClass('animated fadeIn');
      }
    });
  }
  if ($timeline_broadcast_item.length) {
    $timeline_broadcast_item.each(function () {
      const $this = $(this); // 当前元素
      // 检查当前元素是否在视口中
      if (isElementInViewport(this, 680)) {
        $this.addClass('hidden')
        $this.removeClass('hidden').addClass('animated fadeInRight');
      }
    });
  }
});

// 触发一次以确保在加载时检查
$(window).trigger('scroll');

</script>

<template>
  <div class="whole">
    <div class="background-image-container">
      <audio loop autoplay>
        <source src="../assets/music/兜兜转转-小京东.mp3">
      </audio>
      <div style="padding-top: 10px">
        <!--        <audio autoplay>-->
        <!--          <source src="../../public/music/退后-周杰伦.mp3"/>-->
        <!--        </audio>-->
        <div style="height: 80vh; display: flex; align-items: center" class="block text-center">
          <div class="welcome-title animated fadeInUp">
            <n-gradient-text
                :gradient="{
      from: 'rgb(85, 85, 85)',
      to: 'rgb(170, 170, 170)',
    }"
            >
              Welcome to LOVE.ZCY.FOREVER
            </n-gradient-text>
          </div>
        </div>
        <div style="margin-top: 10rem">
          <div style="color: white; font-size: 20px; font-weight: bolder">人物简介</div>
          <div style="color: white; font-size: 13px; font-weight: lighter">ZCY INFO</div>
          <div class="my-fadeIn" style="display: flex; align-items: center; width: 1300px">
            <div style="margin-left: 230px; margin-top: 30px">
              <vs-card style="height: 250px" type="2">
                <template #title>
                  <h3>钟晨瑶</h3>
                </template>
                <template #img>
                  <img style="width: 500px" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/zcy2.webp" alt="">
                </template>
                <template #text>
                  <p>
                    《完蛋！我被美女包围了》真人互动影游——“午夜精灵”郑梓妍
                  </p>
                </template>
              </vs-card>
            </div>
            <div class="personal-info-area" style="width: 100%; margin-left: 50px; margin-top: 30px;">
              <div class="personal-info-area-text">
                <div class="personal-info-line">
                  1998年，钟晨瑶出生于福建省三明市，并在那里读书、长大。童年时她经常参加学校或县城里的晚会、舞蹈演出等，初中时学习过一年美声。高中时期一次偶然的机会，她了解到了演员希斯莱杰的故事，心里埋下了想当演员的种子。在华东政法大学就读两年后，她毅然决定退学重新参加高考，被中央戏剧学院录取。
                </div>
                <div class="personal-info-line">
                  2020年1月21日，主演的贺岁微电影《遇见你》发布，这是张艺谋导演团队竖屏美学系列微电影的第一辑。2022年3月3日，与樊治欣、乔靖雯联袂主演的古装爱情轻喜剧《谢公子的酒》在腾讯视频独播，在剧中饰演男主人公谢退思的表妹、酒肆里的员工小棠。同年8月，她和同学一起代表中戏参加“爱·庐山”影视短片季，参赛作品《淙淙》获得该届影视短片季最佳影片、2022年SHISW短片奖十一月最佳故事短片荣誉提名。
                </div>
              </div>
            </div>
          </div>
          <div class="my-fadeIn" style="display: flex; align-items: center; width: 1300px">
            <div class="personal-info-area "
                 style="width: 100%; margin-left: 230px; margin-top: 30px; margin-right: 50px">
              <div class="personal-info-area-text">
                <div class="personal-info-line">
                  2023年7月31日，她主演的剧情短片《离校日》发布，她在片中饰演男主人公王杰暗恋了两年的同班同学叶豫文。同年10月12日，与梁戟、刘添祺领衔主演的剧情电影《二次呼吸》在第七届平遥国际电影展首映。
                </div>
                <div class="personal-info-line">
                  同年10月18日，与王姿允、邹佳佳等人领衔主演的真人恋爱影视游戏《完蛋！我被美女包围了！》上线，在游戏中饰演午夜精灵郑梓妍。11月30日，与周冬雨、宋洋等人共同出演的剧情电影《热搜》上映，在片中饰演实习生温妮。
                </div>
              </div>
            </div>
            <div class="my-fadeIn" style="margin-right: auto; margin-top: 30px">
              <vs-card style="height: 100%; width: 250px" type="2">
                <template #title>
                  <h3>钟晨瑶</h3>
                </template>
                <template #img>
                  <img src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/zcy1.webp" alt="">
                </template>
                <template #text>
                  <p>
                    《热搜》北京首映礼——在剧中饰演实习生“温妮”
                  </p>
                </template>
              </vs-card>
            </div>
          </div>
          <div class="my-fadeIn" style="display: flex; align-items: center; width: 1300px">
            <div style="margin-left: 230px; margin-top: 30px;">
              <vs-card style="height: 220px; width: 100%" type="2">
                <template #title>
                  <h3>钟晨瑶</h3>
                </template>
                <template #img>
                  <img src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/zcy3.webp" alt="">
                </template>
                <template #text>
                  <p>
                    芒果TV重点项目、短剧《暗夜浪漫》——设计师许望
                  </p>
                </template>
              </vs-card>
            </div>
            <div class="personal-info-area"
                 style="width:100%; margin-left: 50px; margin-top: 30px;">
              <div class="personal-info-area-text">
                <div class="personal-info-line animate__backInLeft">
                  2024年3月21-24日，在中国国家话剧院原创话剧《蓟州疑云》中反串饰演小铺兵罗小本。该剧获得了北京文化艺术基金的年度资助，作为开幕大戏为中国国家话剧院和西城区人民政府联合主办的第九届中国原创话剧邀请展拉开帷幕。
                </div>
                <div class="personal-info-line">
                  2024年3月30日，领衔主演的网剧《咸鱼反击》正式开机，在剧中饰演大姐大郭苗。该剧于2024年4月24日顺利杀青。2024年8月22日，主演的短剧《暗夜浪漫》在芒果TV正式上线，在剧中一人分饰两角，饰演孪生姐妹许希/许望。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 8rem">
          <div style="color: white; font-size: 20px; font-weight: bolder">线下动态</div>
          <div style="color: white; font-size: 13px; font-weight: lighter; margin-bottom: 3rem">ZCY OFFLINE EVENTS</div>

          <el-scrollbar>
            <div style="display: flex; height: 440px" class="my-fadeInRight animated fadeIn">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶北京路演.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶上海路演.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶杭州路演.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶广州路演.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶重庆路演.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶郑州路演.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶成都cf.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶厦门电影节.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶广州gogo.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶广州漫展.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶北京话剧.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶杭州漫展.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶上海520.webp" alt="">
              <img class="carousel-img" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶沈阳漫展.webp" alt="">
            </div>
          </el-scrollbar>

          <div class="my-fadeInRight" style="margin-bottom: 3rem; margin-top: 1rem; padding: 1rem">
            <el-scrollbar style="height: 120px;">
              <n-timeline horizontal>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.11.25</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">北京</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">《热搜》首映礼</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.11.27</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">上海</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.11.28</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">杭州</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item color="green">
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.11.29</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">广州</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.11.30</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">重庆</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.12.01</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">郑州</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.12.07</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">成都</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">CNCF文殊坊助威夜</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.12.19-20</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">厦门</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">厦门.柬埔寨电影周</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.12.25</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">广州</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">腾讯游戏“转运节”</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2023.12.30</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">广州</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">广州萤火虫漫展</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item color="green">
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2024.03.21-24</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">北京</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">《蓟州疑云》话剧</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item color="green">
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2024.03.24</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">杭州</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">杭州AD02漫展</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2024.05.20</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">上海</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">哔哩哔哩 520 扫楼</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item color="green">
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">2024.08.16</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">沈阳</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">沈阳SSCA漫展</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
                <n-timeline-item>
                  <div class="offline-events-timeline-item">
                    <div class="offline-events-timeline-item-content">
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">未来</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                             alt="">
                        <div class="offline-events-timeline-item-content-text">未知</div>
                      </div>
                      <div style="display: flex">
                        <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                        <div class="offline-events-timeline-item-content-text">未完待续</div>
                      </div>
                    </div>
                  </div>
                </n-timeline-item>
              </n-timeline>
            </el-scrollbar>
          </div>
        </div>
        <div style="margin-top: 6rem">
          <div style="color: white; font-size: 20px; font-weight: bolder">直播动态</div>
          <div style="color: white; font-size: 13px; font-weight: lighter">ZCY LIVE BROADCAST</div>
          <div>
            <n-timeline class="timeline-broadcast">
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1JqmDYqEe3?spm_id_from=333.788.player.switch&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a&p=2">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image%2F2024.11.14.jpg" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.11.14</div>
                        <div class="broadcast-item-text">今天也是下班早的一天耶✌️</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1XEDrYzEhB/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.11.09.jpg" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.11.09</div>
                        <div class="broadcast-item-text">失踪人口回归🎉</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1YjCDYjE5j/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.10.18.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.10.18</div>
                        <div class="broadcast-item-text">一周年快乐！🎉</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1X92yYqEHA/?spm_id_from=333.999.0.0">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.10.07.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.10.07</div>
                        <div class="broadcast-item-text">四川口音瑶限时登场</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1f219YgE9b/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.10.05.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.10.05</div>
                        <div class="broadcast-item-text">嘟嘟嘟哒哒哒终于放假啦！🥳</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV12Xx7eDEHR/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.26.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.09.26</div>
                        <div class="broadcast-item-text">v我50之和瑶瑶一起疯狂星期四！🍗🍺</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV17ntvesEnt/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.17.(2).webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.09.17</div>
                        <div class="broadcast-item-text">和瑶瑶一起过中秋🥮（下）</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1p1tYebEDV/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.17.(1).webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.09.17</div>
                        <div class="broadcast-item-text">和瑶瑶一起过中秋🥮（上）</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1wA4mehEdJ/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.15.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.09.15</div>
                        <div class="broadcast-item-text">难得下班早的一天💨</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1X6HseMEk8/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.03.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.09.03</div>
                        <div class="broadcast-item-text">今天是机场吃播瑶😋</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV15aWveGEJu/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.25.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.08.25</div>
                        <div class="broadcast-item-text">Happy birthday!🎂🎈🎁🎊</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV16gWMerEdv/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.21.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.08.21</div>
                        <div class="broadcast-item-text">黑神话·悟空—重生之我是天命瑶🐵</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1qYYJeZEF2/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.10.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.08.10</div>
                        <div class="broadcast-item-text">陪瑶瑶过的第一个七夕💌</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1niige1EAi/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.02.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.08.02</div>
                        <div class="broadcast-item-text">早六晚九的疲惫瑶😴</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1cuvve7EMD/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.01.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.08.01</div>
                        <div class="broadcast-item-text">瑶瑶染发记（第三部）👱‍♀️</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1tw4m1k7Gk/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.07.20.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.07.20</div>
                        <div class="broadcast-item-text">喜欢和爱是需要表达的🩷</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Cx4y147Ps/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.07.01.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.07.01</div>
                        <div class="broadcast-item-text">梦回杭州漫展👗</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Vm421V7Zd/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.06.14.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.06.14</div>
                        <div class="broadcast-item-text">我不想毕业之对宿舍恋恋不舍瑶🎓</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1GT421e7Bs/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.06.10.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.06.10</div>
                        <div class="broadcast-item-text">呜呼呜呼！珍珍首次登场！🥳</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Pi421S72m/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.21.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.05.21</div>
                        <div class="broadcast-item-text">魔都矩阵闯关瑶🥇</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1eH4y1u7ko/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.20(2).webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.05.20</div>
                        <div class="broadcast-item-text">和瑶瑶一起过520🩷</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Rs421w7sy/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.20(1).webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.05.20</div>
                        <div class="broadcast-item-text">和瑶瑶一起去哔哩哔哩扫楼🥳</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1kf421U7uU/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.03.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.05.03</div>
                        <div class="broadcast-item-text">和瑶瑶一起去看音乐节🎵</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1fr421u7mo/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.02.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.05.02</div>
                        <div class="broadcast-item-text">和簪花瑶一起夜游闽越水镇🌸🚣</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1hw4m1C767/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.04.30.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.04.30</div>
                        <div class="broadcast-item-text">今天是电影节下班后的礼服瑶️💃</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1PJ4m1n7HU/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.04.24.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.04.24</div>
                        <div class="broadcast-item-text">和瑶瑶一起打气球、套小玩具🎈🧸</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1bi42127Wh/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.04.18.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.04.18</div>
                        <div class="broadcast-item-text">清晨偷袭之和瑶瑶一起吃早餐🥣</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1dm411k7c1/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.03.31.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.03.31</div>
                        <div class="broadcast-item-text">和瑶瑶一起去逛街吃美食🍟🍔</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Gz421o7hN/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.03.29.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.03.29</div>
                        <div class="broadcast-item-text">瑶瑶染发记（第二部）👱‍♀</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1dy421z77C/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.24.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.02.24</div>
                        <div class="broadcast-item-text">和瑶瑶一起欢度元宵🎆</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1yZ42117k2/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.20.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.02.20</div>
                        <div class="broadcast-item-text">擅自开播的呆呆瑶首次登场😳</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1j4421F7cm/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.14.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.02.14</div>
                        <div class="broadcast-item-text">陪瑶瑶过的第一个情人节❤️</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Ju4m1K7um/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.09.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.02.09</div>
                        <div class="broadcast-item-text">除夕夜之和瑶瑶一起唱歌放烟花！🎤🎇</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1f4421A7A6/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.08.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.02.08</div>
                        <div class="broadcast-item-text">元...元...元梦之星启动！🎮</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Rv4211734/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.05.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.02.05</div>
                        <div class="broadcast-item-text">和瑶瑶一起秋秋飞车！🚗</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1GK411Y7cJ/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.01.23.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.01.23</div>
                        <div class="broadcast-item-text">瑶瑶染发记（第一部）👱‍♀️</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1y5411v7sE/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.01.11.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2024.01.11</div>
                        <div class="broadcast-item-text">偷袭之凌晨三点不睡觉准有好事🕒</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Tw411G7sR/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.12.25.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.12.25</div>
                        <div class="broadcast-item-text">和瑶瑶一起畅玩 gogo 新天地🎄</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1ye411C7V9/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.12.10.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.12.10</div>
                        <div class="broadcast-item-text">古希腊之掌管答案之书的瑶👸</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1XN4y1176Z/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.26.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.11.26</div>
                        <div class="broadcast-item-text">率土之滨之钟极大招！🎮</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Gw411K7sD/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.18.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.11.18</div>
                        <div class="broadcast-item-text">英雄联盟手游“团魂之夜”之吃播瑶🍽️</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Z84y1Q7g5/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.09.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.11.09</div>
                        <div class="broadcast-item-text">郑梓妍经典动作再现🫰</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1fG411X7SY/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.05.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.11.05</div>
                        <div class="broadcast-item-text">和瑶瑶一起在天台吹风🏡</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Wc411d71h/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.10.30.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.10.30</div>
                        <div class="broadcast-item-text">世界上又多了一名百万粉丝博主🎉</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1DN411x7EW/?spm_id_from=333.337.search-card.all.click">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.10.23.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.10.23</div>
                        <div class="broadcast-item-text">日不落 live 封神现场！🎤</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
              <n-timeline-item class="timeline-broadcast-item">
                <a target="_blank"
                   href="https://www.bilibili.com/video/BV1Xe411R7Ys/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a">
                  <div class="broadcast-item">
                    <div class="broadcast-item-content">
                      <img class="broadcast-item-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.10.22.webp" alt="">
                      <div class="broadcast-item-time-and-text">
                        <div class="broadcast-item-time">2023.10.22</div>
                        <div class="broadcast-item-text">初出茅庐 请多多关照！🙈</div>
                      </div>
                    </div>
                  </div>
                </a>
              </n-timeline-item>
            </n-timeline>
          </div>
        </div>
        <n-back-top :right="60" :bottom="60"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

.whole {
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: url("https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/pricing-bg-dot-shape.png");
  background-attachment: fixed;
}

.background-image-container {
  max-width: 1500px;
  background-size: cover;
  background-position: center;
}

.welcome-title {
  font-size: 80px;
  color: white;
  font-family: Georgia, serif;
  font-style: italic;
  font-weight: 50;
  margin-left: auto;
  margin-right: auto;
  transition: font-size 0.4s ease;
}

.welcome-title:hover {
  font-size: 90px;
}

.personal-info-line {
  text-indent: 2em;
  font-size: 17px;
  color: white;
  text-align: left;
  margin-top: 10px;
  padding: 8px;
}

.personal-info-area {
  border-radius: 10px;
}

.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}


.hidden {
  display: none;
}

.animated.fadeIn {
  animation-duration: 3.0s; /* 设置动画持续时间为 2 秒 */
}

.animated.fadeInLeft {
  animation-duration: 2.5s; /* 设置动画持续时间为 2 秒 */
}

.animated.fadeInRight {
  animation-duration: 2.5s; /* 设置动画持续时间为 2 秒 */
}

.animated.bounceInRight {
  animation-duration: 1.5s; /* 设置动画持续时间为 2 秒 */
}

.animated.fadeInUp {
  animation-duration: 1.5s; /* 设置动画持续时间为 2 秒 */
}

.offline-events-timeline {
  margin-top: 4rem;
  padding-left: 28rem;
}

.offline-events-timeline-item {
  display: flex;
}

.offline-events-timeline-item-content {
  color: #ebebeb;
  font-size: 12px;
}

.offline-events-timeline-item-image {
  border-radius: 10px;
  height: 100px;
  margin-left: 1rem;
}

.offline-events-timeline-item-content-icon {
  height: 16px;
  margin: 0.2rem;
}

.offline-events-timeline-item-content-text {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  padding: 0.2rem
}

.timeline-broadcast {
  padding-left: 25rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.broadcast-item {
  border-radius: 15px;
  width: 47rem;
  height: 10rem;
  cursor: pointer;
}

.broadcast-item:hover {
  background-color: rgba(247, 247, 247, 0.15);
  transition: background 0.6s;
}

.broadcast-item:hover .broadcast-item-text {
  color: #409eff !important;
  font-size: 35px;
}

.broadcast-item:hover .broadcast-item-time {
  color: #409eff !important;
  font-size: 20px;
}

.broadcast-item:hover .broadcast-item-image {
  height: 135px;
}

.broadcast-item-image {
  height: 100px;
  border-radius: 10px;
  transition: height 0.3s ease;
}

.broadcast-item-time {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  color: #ebebeb;
  font-size: 14px;
  font-weight: 500;
  font-family: Georgia, serif;
  transition: font-size 0.3s ease;
}

.broadcast-item-text {
  margin-top: 0.8rem;
  color: #ebebeb;
  font-size: 30px;
  padding: 0.35rem;
  font-weight: 400;
  font-family: Georgia, serif;
  transition: font-size 0.3s ease;
}

.broadcast-item-content {
  padding-left: 0.8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  display: flex;
  justify-items: center;
}

.broadcast-item-time-and-text {
  margin-left: auto;
  margin-right: auto;
}

.carousel-img {
  height: 400px;
}

</style>