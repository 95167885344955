import axios from 'axios';

// 定义后端接口的基础 URL
const baseURL = 'https://backend.lovezcy.com:8085/zcy';
// const baseURL = 'https://localhost:8085/zcy';

// 创建 axios 实例
const api = axios.create({
    baseURL,
    timeout: 60000, // 设置超时时间为 5 秒
});


// 响应拦截器，可以在响应返回后进行一些处理，比如统一处理错误
api.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        console.error('请求错误：', error);
        return Promise.reject(error);
    }
);

// 封装一个获取数据的函数
const listMessage = async () => {
    return (await api.get('/message/list'));
};

const sendMessage = async (message) => {
    return (await api.post('/message/add', message))
}

export
{
    listMessage,
    sendMessage
}
    ;