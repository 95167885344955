<script setup>
function DateDiffer(Date_end) {
  //date1开始时间
  let date1 = new Date(Date_end);
  //date2当前
  let date2 = new Date();
  date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  const diff = date2.getTime() - date1.getTime(); //目标时间减去当前时间
  //计算当前时间与结束时间之间相差天数
  return diff / (24 * 60 * 60 * 1000)
}
</script>

<template>
  <div class="whole">
    <div class="footer">
      <div style="width: 100%">
        <div class="footer-item">
          <div style="display: flex; align-items: center; padding: 1rem">
            <div class="my-title">LOVE.ZCY.FOREVER</div>
            <p class="my-love-time">DAY {{ DateDiffer('2023-10-29') }}</p>
          </div>
          <div style="color: rgba(255,255,255,0.52); font-size: 11px; font-weight: 500 ">
            <p style="text-align: left; padding: 1rem">
              LOVE.ZCY.FOREVER成立于2024年10月07日，本站是非盈利组织，主要收集瑶瑶的最新动态、影视、音乐、图集等内容，
              并为瑶瑶乐们提供更多交流分享瑶瑶的途径。欢迎广大瑶瑶乐们踊跃投稿，共同建设此站，作者也希望此站能陪伴瑶瑶到永远❤️(陪伴瑶瑶快一周年了，也算小小整个活叭～)
            </p>
          </div>
        </div>
        <div class="footer-item" style="padding: 1rem">
          <el-collapse>
            <el-collapse-item title="快速导航" name="1">
              <div class="footer-item">
                <div style="text-align: left;">
                  <div class="footer-item-text">
                    <img style="height: 16px" alt="" src="../assets/icon/zuozhe.webp">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="@钟心耿耿，瑶瑶与珍珍的骨灰级粉丝"
                        placement="right-end"
                    >
                      <p style="margin-left: 0.3rem">关于作者</p>
                    </el-tooltip>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/youxiang.webp">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="473165055@qq.com"
                        placement="right-end"
                    >
                      <p style="margin-left: 0.3rem">
                        投稿邮箱
                      </p>
                    </el-tooltip>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/yiganxie.webp">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="@战斗天使ZCY、@cc7、@狂暴椰菜汁、@加菲owo、@王志宇-"
                        placement="bottom"
                    >
                      <p style="margin-left: 0.3rem">特别鸣谢</p>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="更多精彩" name="2">
              <div class="footer-item">
                <div style="text-align: left;">
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/douyin.webp">
                    <p style="margin-left: 0.3rem"><a target="_blank"
                                                      href="https://www.douyin.com/user/MS4wLjABAAAAsT9aCr8lnmQHMzfD1nQmLZ9mHoQPg4P5LMbSUkncMik?from_tab_name=main">@战斗天使ZCY</a>
                    </p>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/weibo.webp">
                    <p style="margin-left: 0.3rem"><a target="_blank"
                                                      href="https://weibo.com/u/3179885602">@钟晨瑶</a>
                    </p>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/bilibili.webp">
                    <p style="margin-left: 0.3rem"><a target="_blank"
                                                      href="https://space.bilibili.com/454888779?spm_id_from=333.337.0.0">@钟晨瑶本人来噜</a>
                    </p>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/xiaohongshu.webp">
                    <p style="margin-left: 0.3rem"><a target="_blank"
                                                      href="https://www.xiaohongshu.com/user/profile/5a348ec211be10147a3b5b7b">@钟晨瑶ZCY</a>
                    </p>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="友情链接" name="3">
              <div class="footer-item" style="width: 220px">
                <div style="text-align: left;">
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/lianjie.webp">
                    <p style="margin-left: 0.3rem"><a href="https://zhongchenyao.net/"
                                                      target="_blank">钟晨瑶的小网站</a></p>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/lianjie.webp">
                    <p style="margin-left: 0.3rem"><a href="https://www.zhongchenyao1998.com/"
                                                      target="_blank">致钟晨瑶</a>
                    </p>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/lianjie.webp">
                    <p style="margin-left: 0.3rem"><a href="https://zhongchenyao.asia/index.html"
                                                      target="_blank">送给世界上最好的钟姐</a></p>
                  </div>
                  <div class="footer-item-text">
                    <img style="height: 18px" alt="" src="../assets/icon/lianjie.webp">
                    <p style="margin-left: 0.3rem"><a href="https://zcy.dhbxs.top/"
                                                      target="_blank">钟晨瑶的小站</a></p>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.whole {
  background-color: #1e1e1e;
  display: flex;
  align-items: center;
}

.my-love-time {
  font-size: 35px;
  font-family: serif;
  color: #ebebeb;
  width: 100%;
  margin: auto;
}

.footer {
  margin-top: 1rem;
}

.footer-item-text {
  display: flex;
  color: #7a7c7e;
  font-size: 11px;
  font-weight: 500;
  padding-top: 1rem;
}

.active-link {
  color: #409eff !important;
}

::v-deep .el-collapse {
  border-top: 0;
}

::v-deep .el-collapse-item__header {
  background-color: #1e1e1e;
  border: #1e1e1e;
  color: rgba(235, 235, 235, 0.7);
}

::v-deep .el-collapse-item__content {
  background-color: #1e1e1e;
}

::v-deep .el-collapse-item__wrap {
  border-bottom: 0;
}
</style>