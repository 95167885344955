<script setup>
import {ref} from "vue";

const cdImages = ref([
  {src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/住进这风里.webp', link: 'https://y.qq.com/n/ryqq/albumDetail/003J66VR31VBfZ'},
  {src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/保你好命.webp', link: 'https://y.qq.com/n/ryqq/albumDetail/003PDgB32xRP7g'},
  {
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/这一刻我掉进能够呼吸的大海.webp',
    link: 'https://music.163.com/#/album?id=179001640'
  },
  {src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/无法触及的神明.webp', link: 'https://y.qq.com/n/ryqq/albumDetail/0049IVKb0KS3dk'},
]);

const songList = [
  {
    source: require('../assets/music/日不落.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/日不落.webp',
    word: '我要送你日不落的想念 寄出代表爱的明信片',
    date: '2023.10.22',
    songTitle: '日不落',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/下雨天.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/下雨天.webp',
    word: '下雨天了怎么办我好想你 不敢打给你我找不到原因',
    date: '2023.11.15',
    songTitle: '下雨天',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/带你去旅行.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/带你去旅行.webp',
    word: '我想要带你去浪漫的土耳其 然后一起去东京和巴黎',
    date: '2023.11.17',
    songTitle: '带你去旅行',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/素颜.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/素颜.webp',
    word: '如果再看你一眼 是否还会有感觉',
    date: '2023.12.26',
    songTitle: '素颜',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/住进这风里.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/住进这风里.webp',
    word: '我想和你住进这风里 我想拥抱你在这黎明',
    date: '2024.01.28',
    songTitle: '住进这风里',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/保你好命.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/保你好命.webp',
    word: '你可以 Body Shining Body Shining 像个明星',
    date: '2024.01.30',
    songTitle: '保你好命',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/这一刻我掉进能够呼吸的大海.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/这一刻我掉进能够呼吸的大海.webp',
    word: '没有你的未来失去天空的色彩',
    date: '2024.02.14',
    songTitle: '这一刻我掉进能够呼吸的大海',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/搁浅.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/搁浅.webp',
    word: '读完了依赖 我很快就离开',
    date: '2024.07.01',
    songTitle: '搁浅',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/无法触及的神明.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/无法触及的神明.webp',
    word: '你像是夜晚的神明 掠夺我身体 不留下这一丝痕迹',
    date: '2024.08.30',
    songTitle: '无法触及的神明',
    singer: '钟晨瑶'
  },
  {
    source: require('../assets/music/变态少女想人记.mp3'),
    img: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/变态少女想人记.webp',
    word: '上厕所的时候不敢想你 怕臭臭的空气亵渎了你',
    date: '2024.09.17',
    songTitle: '变态少女想人记',
    singer: '钟晨瑶'
  }
];

</script>

<template>
  <div class="whole">
    <div style="display: flex; padding: 5.5rem 0.5rem 0.5rem; justify-content: center">
      <vs-avatar size="110" circle style="border: white">
        <img src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/战斗天使ZCY.webp" alt="">
      </vs-avatar>
      <div style="display: flex">
        <div style="margin-top: 0.1rem; margin-left: 1rem">
          <div style="display: flex">
            <p class="animated fadeInRight"
               style="font-family: '阿里妈妈刀隶体 Regular'; font-size: 30px; color: #d3dce6">
              爱唱歌的ZCY
            </p>
          </div>
          <div style="height: 0.8rem;margin-left: 0.15rem; margin-top: 0.5rem; display: flex; align-items: center">
            <img style="height: 0.8rem" src="../assets/icon/Subtraction.webp" alt="">
            <p class="actor-text">歌手</p>
          </div>
          <div style="display: flex; margin-top: 1rem">
            <div style="display: flex">
              <div class="data-statistics-number animated fadeInLeft">2229.9w</div>
              <div class="data-statistics-text">获赞</div>
            </div>
            <div style="display: flex">
              <div class="data-statistics-number animated fadeInLeft">123</div>
              <div class="data-statistics-text">关注</div>
            </div>
            <div style="display: flex">
              <div class="data-statistics-number animated fadeInLeft">160.0w</div>
              <div class="data-statistics-text">粉丝</div>
            </div>
          </div>
          <n-dialog-provider>
            <div class="follow-button">
              <div style="font-size: 11px; font-weight: 500">特别关注</div>
              <img style="height: 13px; margin-left: 0.5rem" src="../assets/icon/daosanjiao.webp" alt="">
            </div>
          </n-dialog-provider>
        </div>
      </div>
    </div>
    <div class="animated fadeIn" style="color: white; font-size: 18px; font-weight: bolder; margin-top: 6rem">所有专辑</div>
    <div class="animated fadeIn" style="color: white; font-size: 11px; font-weight: lighter">CDS OF ZCY</div>
    <div class="animated fadeIn" style="width: 90%; margin-left: auto; margin-right: auto; margin-top: 2rem">
      <el-carousel :interval="4000" type="card" height="150px">
        <el-carousel-item v-for="(item, index) in cdImages" :key="index">
          <a :href="item.link" target="_blank">
            <img :src="item.src" alt="Carousel Image" style="width: 100%; height: auto;">
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="animated fadeIn" style="color: white; font-size: 18px; font-weight: bolder; margin-top: 7rem;">在线播放</div>
    <div class="animated fadeIn" style="color: white; font-size: 11px; font-weight: lighter">PLAY ONLINE</div>
    <a-timeline class="animated fadeIn" style="margin-top: 2rem; padding: 1rem">
      <a-timeline-item v-for="item in songList" :key="item.source">
        <div>
          <div class="timeline-text">{{ item.date }}</div>
          <div class="my-audio-player">
            <div style="display: flex; padding: 1rem; margin-left: 0.5rem">
              <img class="cd-image" :src="item.img" alt="">
              <div>
                <div class="player-in-song-description-text">
                  <div class="song-title">{{ item.songTitle }}</div>
                </div>
                <div class="song-word-text">{{item.word}}</div>
              </div>
            </div>
            <div style="display: flex; justify-items: center">
              <audio controls ref="audioPlayer" class="player-line"
                     style="height: 32px; width: 88%; margin-left: auto; margin-right: auto;">
                <source :src="item.source"/>
              </audio>
            </div>
          </div>
        </div>
      </a-timeline-item>
    </a-timeline>
    <div style="color: rgba(235,235,235,0.7); padding-bottom: 2rem">持续更新中...</div>
    <n-back-top :right="20" :bottom="20"/>
  </div>
</template>

<style scoped>
.whole {
  background-color: rgba(0, 0, 0, 0.95);
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: url("https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/pricing-bg-dot-shape.png");
  background-attachment: fixed;
}

.actor-text {
  margin-left: 0.3rem;
  color: white;
  font-weight: 500;
  font-size: 11px
}

.data-statistics-number {
  color: white;
  font-size: 13px;
  font-weight: 1000;
  padding: 0.1rem;
}

.follow-button {
  width: 5rem;
  height: 1.7rem;
  background-color: #383838;
  color: #ebebeb;
  border-radius: 5px;
  margin-top: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.follow-button:hover {
  background-color: #212121;
}

.data-statistics-text {
  color: gray;
  font-size: 11px;
  padding-top: 0.2rem;
  padding-left: 0.1rem;
  margin-right: 0.5rem;
}

.song-text {
  justify-content: center;
  color: rgba(255, 255, 255, 0.52);
  margin: 3rem auto;
}

.cd-image{
  height: 60px;
  width: 60px;
}

.timeline-text {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: rgba(245, 245, 245, 0.4);
  text-align: left;
  margin-left: 0.8rem;
}

.my-audio-player {
  height: 150px;
  width: 95%;
  border-radius: 20px;
  background-color: rgba(247, 247, 247, 0.3);
}

.player-in-song-description-text {
  margin-left: 0.3rem;
  display: flex;
  color: #ebebeb;
  font-size: 15px;
  font-weight: 500;
}

.song-word-text {
  margin-left: 0.3rem;
  margin-top: 0.6rem;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
}

.song-title{
  color: #ebebeb;
  font-size: 14px;
}

.animated.fadeIn {
  animation-duration: 3s; /* 设置动画持续时间为 2 秒 */
}

</style>