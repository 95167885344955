<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue'; // swiper所需组件
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'vuesax/dist/vuesax.css'
import {onMounted, ref} from "vue";
import {listPost} from "@/api/postService";
import $ from "jquery";
import {PhotoConsumer, PhotoProvider} from "vue3-photo-preview";

onMounted(() => {
  searchPostList('', 0)
})

// 抖音数据
const douyin_data = ref({
  likes: 2277.1,
  follow: 127,
  fans: 158.4
})

// 微博数据
const weibo_data = ref({
  fans: 32.1,
  follow: 168,
  likes: 116
})

// 小红书数据
const redbook_data = ref({
  follow: 305,
  fans: 12.5,
  likes: 52.7
})

// b站数据
const bilibili_data = ref({
  fans: 15.4,
  follow: 30,
  likes: 32.9
})

// 查询条件
const keyword = ref('')

const selected = ref(0);

const select = (platform) => {
  selected.value = platform;
  searchPostList(keyword.value, selected.value)
};

const search = () => {
  searchPostList(keyword.value, selected.value)
}

// 帖子数据
const postList = ref([])

// 数据加载flag
const loading = ref(true)

const searchPostList = (keyword, platform) => {
  const $post = $('.post-area');
  loading.value = true
  $post.each(function () {
    const $this = $(this); // 当前元素
    $this.removeClass('animated fadeIn');
  })
  listPost(keyword, platform).then(res => {
    postList.value = res.data
    loading.value = false
    $post.each(function () {
      const $this = $(this); // 当前元素
      $this.addClass('animated fadeIn');
    })
  })
}

// 获取平台头像
const getAvatar = (platform) => {
  switch (platform) {
    case 1:
      return 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-douyin-avatar.jpeg';
    case 2:
      return 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-weibo-avatar.jpg';
    case 3:
      return 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-xiaohongshu-avatar.webp';
    case 4:
      return 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-bilibili-avatar.webp';
    default:
      return 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-douyin-avatar.jpeg';
  }
}

// 获取平台昵称
const getName = (platform) => {
  switch (platform) {
    case 1:
      return '战斗天使ZCY';
    case 2:
      return '钟晨瑶';
    case 3:
      return '钟晨瑶ZCY';
    case 4:
      return '钟晨瑶本人来噜';
    default:
      return '钟晨瑶';
  }
}

const getPlatformIcon = (platform) => {
  switch (platform) {
    case 1:
      return require('../assets/icon/douyin.webp');
    case 2:
      return require('../assets/icon/weibo.webp');
    case 3:
      return require('../assets/icon/xiaohongshu.webp');
    case 4:
      return require('../assets/icon/bilibili.webp');
  }
}

// 播放器选项，包括 controls 配置
const options = ref({
  controls: [
    'play-large', // 大播放按钮
    'play',       // 播放/暂停按钮
    'progress',   // 进度条
    'current-time', // 当前播放时间显示
    'pip',        // 画中画（如果浏览器支持）
    'airplay',    // （如果设备支持）
    'fullscreen'  // 全屏按钮
  ],
  fullscreen: {
    enabled: true, // 启用全屏功能
    fallback: true, // 如果浏览器原生全屏不可用，尝试使用其他方式全屏
    iosNative: true // 在iOS设备上使用原生全屏模式
  }
});

/* 视频播放 获取第一帧 */
function canplay(event) {
  const target = event.target;
  if (target && target.tagName === 'VIDEO') {
    setTimeout(() => {
      target.pause();
    }, 1);
  }
}

function isMoreThanOneDayAway(dateString) {
  const oneDayInMs = 24 * 60 * 60 * 1000; // 一天的毫秒数
  const targetDate = new Date(dateString);
  const currentDate = new Date();

  const diffInMs = Math.abs(targetDate - currentDate);
  return diffInMs > oneDayInMs;
}

</script>

<template>
  <div class="whole">
    <div style="padding-top: 5rem"></div>
    <div style="width: 90%" class="swiper">
      <swiper
          :slidesPerView="1"
          :spaceBetween="30"
          :loop="true"
          :centeredSlides="true"
          :autoplay="{delay: 2500, disableOnInteraction: false}"
      >
        <swiper-slide>
          <a href="https://www.douyin.com/user/MS4wLjABAAAAsT9aCr8lnmQHMzfD1nQmLZ9mHoQPg4P5LMbSUkncMik" target="_blank">
            <div
                style="width: 100%; height: 12rem; background: RGB(22,24,35); border-radius: 1rem; margin-left: auto; margin-right: auto; display: flex; align-items: center">
              <div style="padding: 1rem">
                <img alt="" src="../assets/icon/douyin.webp" style="height: 30px">
                <span style="color: white; font-size: 12px">抖音</span>
              </div>
              <el-divider direction="vertical" style="height: 10rem"/>
              <div>
                <div style="margin-left: 1rem; display: flex; align-items: center">
                  <img alt=""
                       src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-douyin-avatar.jpeg"
                       style="height: 70px; border-radius: 50%;">
                  <div>
                    <div style="color: white; font-size: 16px; font-weight: 500; margin-left: 0.7rem"> 战斗天使ZCY</div>
                    <div style="display: flex; align-items: center; margin-left: 0.7rem; margin-top: 0.1rem">
                      <img style="height: 10px; padding: 0" src="../assets/icon/Subtraction.webp" alt="">
                      <p style="color: #ebebeb; text-align: left; font-size: 10px; margin-left: 0.3rem; padding-top: 0.1rem; font-weight: 500">
                        演员
                      </p>
                    </div>
                  </div>
                </div>
                <div style="display: flex; margin-top: 0.7rem; margin-left: 1rem">
                  <div style="display: flex; align-items: center; margin-right: 0.8rem">
                    <div style="color: white; font-size: 13px">{{ douyin_data.likes }}万</div>
                    <div style="color: gray; margin-left: 0.3rem; font-size: 11px">获赞</div>
                  </div>
                  <div style="display: flex; align-items: center; margin-right: 0.8rem">
                    <div style="color: white; font-size: 13px">{{ douyin_data.follow }}</div>
                    <div style="color: gray; margin-left: 0.3rem; font-size: 11px">关注</div>
                  </div>
                  <div style="display: flex; align-items: center; margin-right: 0.8rem">
                    <div style="color: white; font-size: 13px">{{ douyin_data.fans }}万</div>
                    <div style="color: gray; margin-left: 0.3rem; font-size: 11px">粉丝</div>
                  </div>
                </div>
                <div style="margin-top: 0.7rem; margin-left: 1rem">
                  <p style="color:white; font-size: 10px; text-align: left; padding-bottom: 0.1rem">不想长大吖</p>
                  <p style="color:white; font-size: 10px; text-align: left; padding-bottom: 0.1rem">🧣钟晨瑶</p>
                  <p style="color:white; font-size: 10px; text-align: left; padding-bottom: 0.1rem">
                    工作📮zhongchenyaowork@163.com
                  </p>
                </div>
              </div>
            </div>
          </a>
        </swiper-slide>
        <swiper-slide>
          <a target="_blank" href="https://weibo.com/u/3179885602">
            <div
                style="width: 100%; height: 12rem; background: RGB(255,130,0,0.8); border-radius: 1rem; margin-left: auto; margin-right: auto; display: flex; align-items: center">
              <div style="padding: 1rem">
                <img alt="" src="../assets/icon/weibo.webp" style="height: 30px">
                <span style="color: white; font-size: 12px">微博</span>
              </div>
              <el-divider direction="vertical" style="height: 10rem"/>
              <div>
                <div style="margin-left: 1rem; display: flex; align-items: center">
                  <img alt=""
                       src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-weibo-avatar.jpg"
                       style="height: 70px; border-radius: 50%;">
                  <div>
                    <div style="color: white; font-size: 16px; font-weight: 500; margin-left: 0.5rem; text-align: left">
                      钟晨瑶
                    </div>
                    <div style="display: flex; margin-top: 0.2rem; margin-left: 0.5rem; text-align: left">
                      <div style="display: flex; align-items: center; margin-right: 0.8rem;">
                        <div style="color: white; font-size: 10px">{{ weibo_data.fans }}万</div>
                        <div style="color: gray; margin-left: 0.3rem; font-size: 8px">粉丝</div>
                      </div>
                      <div style="display: flex; align-items: center; margin-right: 0.8rem;">
                        <div style="color: white; font-size: 10px">{{ weibo_data.follow }}</div>
                        <div style="color: gray; margin-left: 0.3rem; font-size: 8px">关注</div>
                      </div>
                      <div style="display: flex; align-items: center; margin-right: 0.8rem;">
                        <div style="color: white; font-size: 10px">{{ weibo_data.likes }}万</div>
                        <div style="color: gray; margin-left: 0.3rem; font-size: 8px">转评赞</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; margin-left: 1rem; margin-top: 1rem">
                  <img alt="" src="../assets/icon/Subtraction.webp" style="height: 12px"/>
                  <div>
                    <div style="color: white; font-size: 9px; font-weight: 500; text-align: left">《爱唱歌的大学生》
                      选手
                    </div>
                    <div style="color: white; font-size: 9px; font-weight: 500; text-align: left">
                      《完蛋！我被美女包围了！》游戏角色郑梓妍
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </swiper-slide>
        <swiper-slide>
          <a target="_blank" href="https://www.xiaohongshu.com/user/profile/5a348ec211be10147a3b5b7b">
            <div
                style="width: 100%; height: 12rem; background: RGB(255,46,77,0.5); border-radius: 1rem; margin-left: auto; margin-right: auto; display: flex; align-items: center">
              <div style="padding: 1rem">
                <img alt="" src="../assets/icon/xiaohongshu.webp"
                     style="height: 30px; margin-left: auto; margin-right: auto">
                <span style="color: white; font-size: 12px; margin-left: auto; margin-right: auto">小红书</span>
              </div>
              <el-divider direction="vertical" style="height: 10rem"/>
              <div>
                <div style="margin-left: 1rem; display: flex; align-items: center">
                  <img alt=""
                       src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-xiaohongshu-avatar.webp"
                       style="height: 70px; border-radius: 50%;">
                  <div>
                    <div style="display: flex; align-items: center">
                      <div
                          style="color: white; font-size: 16px; font-weight: 500; margin-left: 0.5rem; text-align: left">
                        钟晨瑶ZCY
                      </div>
                      <img alt="" src="../assets/icon/xiaohongshurenzheng.svg"
                           style="height: 14px; margin-left: 0.3rem">
                    </div>
                    <div style="display: flex; margin-top: 0.2rem; margin-left: 0.5rem; text-align: left">
                      <div style="display: flex; align-items: center; margin-right: 0.8rem;">
                        <div style="color: white; font-size: 9px">演员</div>
                        <el-divider direction="vertical" style="height: 6px"></el-divider>
                        <div style="color: gray; font-size: 9px">小红书号：420315756</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; margin-left: 1rem; margin-top: 1rem">
                  <div
                      style="display: flex; align-items: center; justify-content: center;margin-right: 1rem; background: RGB(145,125,124); border-radius: 1rem">
                    <div
                        style="display: flex; align-items: center; justify-content: center; padding: 0.2rem 0.6rem;">
                      <img alt="" src="../assets/icon/female.svg" style="height: 8px">
                      <div style="color:white; font-size: 8px; margin-left: 3px">24岁</div>
                    </div>
                  </div>
                  <div
                      style="display: flex; align-items: center; justify-content: center; margin-right: 1rem; background: RGB(145,125,124); border-radius: 1rem">
                    <div
                        style="color:white; font-size: 8px; margin-left: 3px; padding: 0.2rem 0.6rem;">
                      玻利维亚
                    </div>
                  </div>
                  <div
                      style="display: flex; align-items: center; justify-content: center; margin-right: 1rem; background: RGB(145,125,124); border-radius: 1rem">
                    <div
                        style="color:white; font-size: 8px; margin-left: 3px;padding: 0.2rem 0.6rem;">
                      中央戏剧学院
                    </div>
                  </div>
                </div>
                <div style="display: flex; margin-left: 1rem; margin-top: 1rem;">
                  <div style="margin-right: 1rem">
                    <div style="color: white; font-size: 14px">{{ redbook_data.follow }}</div>
                    <div style="color: RGB(133,133,133); font-size: 12px">关注</div>
                  </div>
                  <div style="margin-right: 1rem">
                    <div style="color: white; font-size: 14px">{{ redbook_data.fans }}万</div>
                    <div style="color: RGB(133,133,133); font-size: 12px">粉丝</div>
                  </div>
                  <div style="margin-right: 1rem">
                    <div style="color: white; font-size: 14px">{{ redbook_data.likes }}万</div>
                    <div style="color: RGB(133,133,133); font-size: 12px">获赞与收藏</div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </swiper-slide>
        <swiper-slide>
          <a href="https://space.bilibili.com/454888779?spm_id_from=333.337.0.0" target="_blank">
            <div
                style="display: flex; align-items:center;width: 100%; height: 12rem; background: RGB(252,139,171); border-radius: 1rem; margin-left: auto; margin-right: auto">
              <div style="padding: 1rem;">
                <img alt="" src="../assets/icon/bilibili.webp"
                     style="height: 30px; margin-left: auto; margin-right: auto">
                <span style="color: white; font-size: 12px; margin-left: auto; margin-right: auto">哔哩哔哩</span>
              </div>
              <el-divider direction="vertical" style="height: 10rem"/>
              <div>
                <div style="margin-left: 1rem; display: flex; align-items: center;">
                  <img alt=""
                       src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-avatar%2Fzcy-bilibili-avatar.webp"
                       style="height: 70px; border-radius: 50%;">
                  <div style="display: flex; margin-left: 0.3rem; margin-top: 1rem;">
                    <div style="margin: 0.2rem">
                      <div style="color: white; font-size: 13px">{{ bilibili_data.fans }}万</div>
                      <div style="color: RGB(133,133,133); font-size: 10px">粉丝</div>
                    </div>
                    <div style="display: flex; align-items: center">
                      <el-divider direction="vertical"></el-divider>
                    </div>
                    <div style="margin: 0.2rem">
                      <div style="color: white; font-size: 13px">{{ bilibili_data.follow }}</div>
                      <div style="color: RGB(133,133,133); font-size: 10px">关注</div>
                    </div>
                    <div style="display: flex; align-items: center">
                      <el-divider direction="vertical"></el-divider>
                    </div>
                    <div style="margin: 0.2rem">
                      <div style="color: white; font-size: 13px">{{ bilibili_data.likes }}万</div>
                      <div style="color: RGB(133,133,133); font-size: 10px">获赞</div>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 1rem">
                  <div style="display: flex; align-items: center">
                    <div
                        style="color: white; font-size: 16px; font-weight: 700; margin-left: 0.5rem; text-align: left">
                      钟晨瑶本人来噜
                    </div>
                    <img alt="" src="../assets/icon/lv6.svg"
                         style="height: 9px; margin-left: 0.5rem">
                    <div
                        style="background: RGB(255,86,141); border-radius: 1rem; margin-left: 0.5rem; height: 1rem; display: flex; align-items: center">
                      <span style="font-size: 9px; padding: 0 0.5rem;color: white">年度大会员</span>
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 0.2rem; margin-left: 0.5rem; text-align: left">
                    <div style="display: flex; align-items: center; margin-right: 0.8rem;">
                      <img alt="" src="../assets/icon/bilibilirenzheng.svg" style="height: 10px; margin-right: 0.2rem;">
                      <div style="color: white; font-size: 8px">bilibili UP主认证：《爱唱歌的大学生》节目研学生 钟晨瑶
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <div class="animated fadeIn" style="color: white; font-size: 18px; font-weight: bolder; padding-top: 3rem">
      更新合集
    </div>
    <div class="animated fadeIn" style="color: white; font-size: 11px; font-weight: lighter">POSTS OF ZCY</div>
    <div class="search-menu">
      <div style="display: flex; justify-content: center; align-items: center; margin-top: 1rem; margin-bottom: 0.5rem">
        <div class="search-box" style="width: 80%; padding: 1rem">
          <img alt="" src="../assets/icon/search.svg" style="height: 20px">
          <input @keyup.enter="search" v-model="keyword" class="search-input" placeholder="尝试输入关键词吧~">
        </div>
        <div @click="search" style="color: rgba(255,255,255,0.6); font-size: 14px; margin-left: 0.5rem">搜索</div>
      </div>
      <div style="color: white; font-size: 13px; text-align: left; padding-left: 6%; font-weight: 500">平台</div>
      <div class="platform-button-group">
        <div class="platform-button"
             :class="{ 'selected': selected === 0 }"
             @click="select(0)">全部
        </div>
        <div class="platform-button"
             :class="{ 'selected': selected === 1 }"
             @click="select(1)">抖音
        </div>
        <div class="platform-button"
             :class="{ 'selected': selected === 2 }"
             @click="select(2)">微博
        </div>
        <div class="platform-button"
             :class="{ 'selected': selected === 3 }"
             @click="select(3)">小红书
        </div>
        <div class="platform-button"
             :class="{ 'selected': selected === 4 }"
             @click="select(4)">哔哩哔哩
        </div>
      </div>
    </div>
    <a-spin v-if="loading" size="small" :spinning="loading"
            style="background: RGB(0,0,0); width: 100%; margin-left: auto; margin-right: auto">
      <div style="height: 10vh; background: RGB(0,0,0)">我很想上班</div>
    </a-spin>
    <div class="post-area">
      <div v-if='postList.length === 0 && loading === false' class="black-background">
        <p class="no-data-text">暂无数据</p>
      </div>
      <div class="post" v-for="item in postList" v-bind:key="item.id">
        <el-divider style="border-color: RGB(56,56,56)"></el-divider>
        <div style="display: flex; align-items: center">
          <img alt="" :src="getAvatar(item.platform)" style="height: 45px; border-radius: 50%">
          <div style="margin-left: 0.5rem; width: 100%">
            <div style="display: flex; width: 100%">
              <div style="color: white; font-size: 12px; font-weight: 600; text-align: left">
                {{ getName(item.platform) }}
              </div>
              <div v-if="!isMoreThanOneDayAway(item.publishTime)"
                   style="margin-left: auto; margin-right: 0.5rem; background-color: red; border-radius: 0.1rem; height: 15px">
                <div style="color: white; font-weight: 1000; font-size: 8px; padding: 0.1rem">NEW</div>
              </div>
            </div>
            <div style="color: gray; font-size: 11px; text-align: left">发布于 {{ item.publishTime }}</div>
          </div>
        </div>
        <pre style="color: white; margin-top: 0.5rem; text-align: left; font-size: 13px; white-space: pre-wrap">{{
            item.content
          }}</pre>
        <div style="color: dodgerblue; text-align: left; font-size: 13px">{{ item.wordList }}</div>
        <div class="resource-area">
          <div v-if="item.type === 2"
               style="margin-top: 0.5rem;width: 75%">
            <vue-plyr
                :options="options"
            >
              <video
                  autoplay
                  preload="metadata"
                  controls
                  crossorigin
                  playsinline
                  @canplay="canplay($event)"
              >
                <source
                    sizes="3840"
                    :src="item.resourceList[0]"
                    type="video/mp4"
                />
              </video>
            </vue-plyr>
          </div>
          <div v-if="item.type === 1" class="image-container">
            <photo-provider :loop="true">
              <photo-consumer class="image-wrapper" v-for="src in item.resourceList" :intro="src" :key="src" :src="src">
                <img :src="src" alt="">
              </photo-consumer>
            </photo-provider>
          </div>
        </div>
        <div style="display: flex; margin-top: 1rem; align-items: center">
          <div style="color: gray; font-size: 11px; text-align: left">来源：</div>
          <img alt="" :src="getPlatformIcon(item.platform)" style="height: 14px">
          <div style="color: white; font-size: 10px; text-align: left; margin-left: 0.5rem">
            @{{ getName(item.platform) }}
          </div>
          <a target="_blank" :href="item.src" style="font-size: 11px; margin-left: 1rem; color: gray">点我进行跳转</a>
        </div>
        <el-divider style="border-color: RGB(56,56,56)"></el-divider>
      </div>
    </div>
    <n-back-top :right="20" :bottom="20"/>
  </div>
</template>

<style scoped>
.whole {
  background-color: rgba(0, 0, 0, 0.95);
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: url("https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/pricing-bg-dot-shape.png");
  background-attachment: fixed;
}

.search-box {
  border-radius: 1rem;
  background: #383838;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  height: 25px;
  width: 100%;
  background: #383838;
  text-indent: 0.5rem;
  border: 0;
  color: white;
  font-size: 13px;
}

.platform-button-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  margin-left: 6%;
}

.platform-button {
  background: RGB(37, 36, 42);
  color: white;
  border-radius: 0.4rem;
  font-size: 13px;
  width: 4.0rem;
  height: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.6rem;
}

.platform-button.selected {
  /* 选中时的边框颜色，可自定义 */
  border: solid 2px red;
  background: RGB(37, 26, 34);
}

.post-area {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 靠左排列 */;
}

.image-wrapper {
  width: 32%; /* 大约每行三张图片，留一些间隙 */
  padding-bottom: 32%; /* 保持正方形 */
  position: relative;
  margin-right: 1%; /* 图片之间的间隙 */
  margin-top: 1%;
  border-radius: 1rem;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保证图片不拉伸，填充整个空间，可能会裁剪 */
  position: absolute;
  top: 0;
  left: 0;
}

.animated.fadeIn {
  animation-duration: 2s;
}

.black-background {
  background-color: black;
  min-height: 10vh; /* 确保背景覆盖整个视口高度 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.no-data-text {
  color: RGB(56, 56, 56);
  font-size: 24px;
}


</style>